import { Helmet } from "react-helmet";
const TaboolaHead = ({ pageType }) => {
    return (
        <Helmet>
            <script type=
                "text/javascript">
                {`
                    window._taboola = window._taboola || [];
                    _taboola.push({ ${pageType}: 'auto' });
                    (function (e, f, u, i) {
                    if (!document.getElementById(i)) {
                    e.async = 1;
                    e.src = u;
                    e.id = i;
                    f.parentNode.insertBefore(e, f);
                    }
                    })(document.createElement('script'),
                    document.getElementsByTagName('script')[0],
                    '//cdn.taboola.com/libtrc/cricketgully/loader.js',
                    'tb_loader_script');
                    if (window.performance && typeof
                    window.performance.mark ===
                    'function') {
                    window.performance.mark('tbl_ic');
                    }
                `}
            </script>
        </Helmet>
    );
};
export default TaboolaHead;