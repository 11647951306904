import React, { useEffect, useMemo, useRef, useState } from 'react'


function analyzeElement(divElement, onHide) {
    if (!divElement) {
        return;
    }
    const id = setInterval(() => {
        try {
            const insElement = divElement.querySelector('ins');
            if (insElement && insElement.getAttribute('data-ad-status') === 'unfilled') {
                // Apply styles to the div
                divElement.style.display = 'none';
                divElement.style.width = '0px';
                divElement.style.height = '0px';
                divElement.style.minHeight = '0px';
              
                // Apply styles to the ins tag
                insElement.style.display = 'none';
                insElement.style.width = '0px';
                insElement.style.height = '0px';
                insElement.style.minHeight = '0px';
                clearInterval(id);
                if (onHide) {
                    onHide();
                }
              }
        } catch (error) {
            
        }
    }, 1000);
}

export const pushGoogleAd = (slotProps) => {
    try {
        if (typeof window === 'object' && window.location.host === "cricketgully.com") {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            console.log('google ad push...  ', slotProps);
        }
    }
    catch (_a) {
        console.log('Error while pushGoogleAd call ', _a, slotProps);
    }
}

// https://medium.com/@deuos/how-to-implement-google-adsense-into-reactjs-803a7d0d3b8d 

let GoogleAdUnit = ({ slotProps, insCustomClass, onHide }) => {
    const [isVisible, setIsVisible] = useState(false);
    const adRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    analyzeElement(adRef.current, onHide);
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (adRef.current) {
            observer.observe(adRef.current);
        }

        return () => {
            if (adRef.current) observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            pushGoogleAd(slotProps);
        }
    }, [isVisible, slotProps]);

    const dataPropsStr = useMemo(() => {
        try {
            return getPropsStr(slotProps);
        } catch (error) {
            return '-';
        }
    }, [slotProps]);

    return (
        <div ref={adRef}>
            {isVisible ? (
                <>
                    <ins
                        className={`adsbygoogle ${insCustomClass || ''} `}
                        {...slotProps}
                        style={{ ...(slotProps.style || {}) }}
                    ></ins>
                    <input type="hidden" name="" value={dataPropsStr} />
                </>
            ) : (
                <div style={{ minHeight: slotProps.style?.height || "250px" }}>
                    
                </div>
            )}
        </div>
    );

}

GoogleAdUnit = React.memo(GoogleAdUnit);

export default GoogleAdUnit;

function getPropsStr(props, del = " | ") {
    const strings = [];
    try {
        for (const key in props) {
            if (key === "style") {
                strings.push('style:' + getPropsStr(props.style, ";"))
            } else {
                strings.push(key + ':' + props[key]);
            }
        }
    } catch (error) {
        console.log('error while calculating getPropsStr ', error);
    }
    return strings.join(del);
}