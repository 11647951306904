import { useEffect } from "react";
const TaboolaWidget = ({ containerId, mode, placement }) => {
    useEffect(() => {
        window._taboola = window._taboola || [];
        window._taboola.push({
            mode,
            container: containerId,
            placement,
            target_type: "mix"
            ,
        });
    }, [containerId, mode, placement]);
    return <div id={containerId}></div>;
};
export default TaboolaWidget;